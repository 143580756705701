import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import arrowSvg from "../images/arrow-circle-right-solid.svg"
import liveTrackingImg from "../images/live-tracking-sq-small.jpg"

class HGS13ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="HGS13"
          description="View route information and buy your ticket for the HGS13 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">HGS13 service</h1>
              <p>All Bus stops between Colton, Leeds – Heckmondwike Grammar School, Wakefield</p>
              <p>Heckmondwike Grammar School, Wakefield - Colton, Leeds</p>
              <p style={{ margin: 0 }}>
                <a className='btn btn-grow btn-quote btn-ticket' href="https://passenger.shuttleid.uk/client/3108be57-5c93-4d16-84ff-0990d87eba95/5e4eb28f-20a4-49c7-b3ce-f6651f845860">
                  <span className='btn-quote__text'>Buy ticket</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </a>
                <a
                  style={{ display: "inline-block", marginLeft: 15 }}
                  href="https://passenger.shuttleid.uk"
                >
                  View My Tickets
                </a>
              </p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <p style={{ margin: 0 }}>
                  <img src={liveTrackingImg} alt="" />
                </p>
              </div>
              <div className="page-content__text">
                <h2 style={{ marginBottom: 15 }}>NEW: Live vehicle tracking</h2>
                <p>Users of the HGS13 service will be able to access live vehicle tracking directly from their ticket, starting January 2022.</p>
                <p>Our new "track bus" feature will allow students and parents to stay informed in real-time, and will complement our existing SMS service alerts, to offer the safest travel experience possible for your child.</p>
              </div>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <h3 style={{ margin: '10px 0' }}>AM route</h3>
                <iframe
                  title="HGS13 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1BUu4oDIFFXO-fs5fy2KxmJBK7KSSTPk&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Heckmondwike grammar school, Wakefield
                </p>
                <p>
                  Sainsbury’s at Colton, Selby Road, Ring Road, Park Lane,
                  Street Lane, Harrogate Road, Shadwell Lane, Wike Ridge Lane, Wigton Lane,
                  Alwoodley Lane, The Avenue, King Lane, Carr Manor Road, Stainbeck Road,
                  Scott Hall Road, Claypit Lane, Leeds Inner Ring Road, Domestic Street,
                  Sydenham Road, A643, M621, Geldard Road, Huddersfield Road, White Lee
                  Road, Batley Road, High Street, Heckmondwike Grammar.
                </p>

                <h3 style={{ margin: '10px 0' }}>PM route</h3>
                <iframe
                  title="HGS13 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1oY1zucbUEmJPP2dcGy3eYnE12VzCAL8&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Heckmondwike grammar school, Wakefield
                </p>
                <p>
                  Heckmondwike Grammar School, Leeds Road, Huddersfield
                  Road, Geldard Road, M621, A643, Leeds Inner Ring Road, Clay Pit Lane, Scott
                  Hall Road, Stainbeck Road, Carr Manor Road, King Lane, The Avenue,
                  Alwoodley Lane, Wigton Lane, Wike Ridge Lane, Shadwell Lane, Harrogate
                  Road, Street Lane, Park Lane, Ring Road, Colton.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Colton</td>
                      <td>DEP</td>
                      <td>0700</td>
                    </tr>
                    <tr>
                      <td>A58/Ring Road</td>
                      <td>DEP</td>
                      <td>0710</td>
                    </tr>
                    <tr>
                      <td>Street Lane</td>
                      <td>DEP</td>
                      <td>0715</td>
                    </tr>
                    <tr>
                      <td>Dexter Pub</td>
                      <td>DEP</td>
                      <td>07:25</td>
                    </tr>
                    <tr>
                      <td>Alwoodley Park And Ride</td>
                      <td>DEP</td>
                      <td>07:35</td>
                    </tr>
                    <tr>
                      <td>Carr Manor</td>
                      <td>DEP</td>
                      <td>07:40</td>
                    </tr>
                    <tr>
                      <td>Domestic Street</td>
                      <td>DEP</td>
                      <td>07:50</td>
                    </tr>
                    <tr>
                      <td>Birstall</td>
                      <td>DEP</td>
                      <td>08:15</td>
                    </tr>
                    <tr>
                      <td>Heckmondwike Grammar</td>
                      <td>ARR</td>
                      <td>08:25</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Heckmondwike Grammar</td>
                      <td>DEP</td>
                      <td>15:30</td>
                    </tr>
                    <tr>
                      <td>Birstall</td>
                      <td>DEP</td>
                      <td>15:35</td>
                    </tr>
                    <tr>
                      <td>Wellington Road</td>
                      <td>DEP</td>
                      <td>15:45</td>
                    </tr>
                    <tr>
                      <td>Carr Manor</td>
                      <td>DEP</td>
                      <td>15:55</td>
                    </tr>
                    <tr>
                      <td>King Lane Park and Ride</td>
                      <td>DEP</td>
                      <td>16:05</td>
                    </tr>
                    <tr>
                      <td>Dexter Pub</td>
                      <td>DEP</td>
                      <td>16:20</td>
                    </tr>
                    <tr>
                      <td>Street Lane</td>
                      <td>DEP</td>
                      <td>16:30</td>
                    </tr>
                    <tr>
                      <td>A58/Ring Road</td>
                      <td>DEP</td>
                      <td>16:40</td>
                    </tr>
                    <tr>
                      <td>Colton</td>
                      <td>ARR</td>
                      <td>16:50</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default HGS13ServicePage
